import React, {
  ComponentType,
  useState,
  MouseEvent,
  useRef,
  FocusEvent,
  forwardRef,
  useImperativeHandle,
  KeyboardEvent,
  Ref,
} from "react";
import { Menu, SxProps, Theme } from "@mui/material";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { MenuItem } from "./MenuItem";
import { IconProps, MenuItemStyleProps } from "./types";

type Props = {
  parentMenuOpen: boolean;
  Icon?: ComponentType<IconProps> | string;
  IconElement?: React.ReactNode;
  TypeIndicatorIcon?: ComponentType<IconProps> | undefined;
  label: string;
  children?: React.ReactNode;
  disabled?: boolean;
  tabIndex?: number;
  RightIconOverride?: ComponentType<IconProps>;
  styleProps?: MenuItemStyleProps;
  content?: React.ReactNode;
  menuStyleOverrides?: SxProps<Theme> | undefined;
  menuItemStyleOverrides?: SxProps<Theme> | undefined;
  menuListClassOverrides?: string;
};

/**
 * This component is largely borrowed from the NestedMenuItem component of the
 * {@link https://github.com/webzep/mui-nested-menu | mui-nested-menu} library
 */
export const NestedMenuItem = forwardRef<HTMLLIElement | null, Props>(
  (
    {
      Icon,
      IconElement,
      TypeIndicatorIcon,
      label,
      children,
      parentMenuOpen,
      disabled,
      tabIndex: tabIndexProp,
      RightIconOverride,
      styleProps,
      content,
      menuStyleOverrides,
      menuItemStyleOverrides,
      menuListClassOverrides,
    }: Props,
    ref
  ) => {
    const containerRefProp: Ref<HTMLElement | null> = null;

    const menuItemRef = useRef<HTMLLIElement | null>(null);
    useImperativeHandle(ref, () => menuItemRef.current!); // eslint-disable-line @typescript-eslint/no-non-null-assertion

    const containerRef = useRef<HTMLDivElement | null>(null);
    useImperativeHandle(containerRefProp, () => containerRef.current);

    const menuContainerRef = useRef<HTMLDivElement | null>(null);

    const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);

    const handleMouseEnter = (e: MouseEvent<HTMLElement>) =>
      setIsSubMenuOpen(true);
    const handleMouseLeave = (e: MouseEvent<HTMLElement>) =>
      setIsSubMenuOpen(false);

    // Check if any immediate children are active
    const isSubmenuFocused = () => {
      const active = containerRef.current?.ownerDocument.activeElement ?? null;

      if (!menuContainerRef.current) return false;
      for (const child of Array.from(menuContainerRef.current.children)) {
        if (child === active) return true;
      }

      return false;
    };

    const handleFocus = (e: FocusEvent<HTMLElement>) => {
      if (e.target === containerRef.current) {
        setIsSubMenuOpen(true);
      }
    };

    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        return;
      }

      if (isSubmenuFocused()) {
        e.stopPropagation();
      }

      const active = containerRef.current?.ownerDocument.activeElement;

      if (e.key === "ArrowLeft" && isSubmenuFocused()) {
        containerRef.current?.focus();
      }

      if (
        e.key === "ArrowRight" &&
        e.target === containerRef.current &&
        e.target === active
      ) {
        const firstChild = menuContainerRef.current
          ?.children[0] as HTMLDivElement;
        firstChild?.focus();
      }
    };

    const open = isSubMenuOpen && parentMenuOpen;

    // Root element must have a `tabIndex` attribute for keyboard navigation
    let tabIndex: number | undefined;
    if (!disabled) {
      tabIndex = tabIndexProp !== undefined ? tabIndexProp : -1;
    }

    return (
      <div
        ref={containerRef}
        onFocus={handleFocus}
        tabIndex={tabIndex}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onKeyDown={handleKeyDown}
      >
        <MenuItem
          ref={menuItemRef}
          Icon={Icon}
          IconElement={IconElement}
          TypeIndicatorIcon={TypeIndicatorIcon}
          label={label}
          disabled={disabled}
          rightContent={
            RightIconOverride ? (
              <RightIconOverride
                className="text-darkGray"
                sx={{ width: "20px" }}
              />
            ) : (
              <ArrowRightIcon className="text-darkGray" fontSize="inherit" />
            )
          }
          styleProps={styleProps}
          content={content}
          menuItemStyleOverrides={menuItemStyleOverrides}
        />
        <Menu
          // Set pointer events to 'none' to prevent the invisible Popover div
          // from capturing events for clicks and hovers
          style={{ pointerEvents: "none" }}
          anchorEl={menuItemRef.current}
          anchorOrigin={{
            horizontal: "right",
            vertical: "top",
          }}
          transformOrigin={{
            horizontal: "left",
            vertical: "top",
          }}
          open={open}
          autoFocus={false}
          disableAutoFocus
          disableEnforceFocus
          onClose={() => {
            setIsSubMenuOpen(false);
          }}
          slotProps={{
            paper: {
              square: true,
              sx: {
                maxWidth: "20rem",
                minWidth: "14rem",
                ...menuStyleOverrides,
              },
            },
          }}
          classes={{ list: menuListClassOverrides }}
        >
          <div ref={menuContainerRef} style={{ pointerEvents: "auto" }}>
            {children}
          </div>
        </Menu>
      </div>
    );
  }
);
