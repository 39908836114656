import React, {
  MouseEvent,
  KeyboardEvent,
  ComponentType,
  forwardRef,
  useState,
  useEffect,
} from "react";
import { Tooltip } from "@mui/material";
import { MenuItem as MuiMenuItem, SxProps, Theme } from "@mui/material";
import { IconProps, MenuItemStyleProps } from "./types";
import { classNames } from "core";

type Props = {
  Icon?: ComponentType<IconProps> | string;
  IconElement?: React.ReactNode;
  TypeIndicatorIcon?: ComponentType<IconProps>;
  label: string;
  rightContent?: React.ReactNode;
  RightIcon?: ComponentType<IconProps>;
  onClick?: (
    e: MouseEvent<HTMLElement> | KeyboardEvent<HTMLElement>,
    textOverride?: string
  ) => void;
  disabled?: boolean;
  input?: boolean;
  defaultInput?: string;
  styleProps?: MenuItemStyleProps;
  tooltipLabel?: string;
  content?: React.ReactNode;
  menuItemStyleOverrides?: SxProps<Theme> | undefined;
};

export const MenuItem = forwardRef<HTMLLIElement, Props>(
  (
    {
      Icon,
      IconElement,
      TypeIndicatorIcon,
      label,
      rightContent,
      onClick,
      disabled,
      input,
      defaultInput,
      RightIcon,
      styleProps,
      tooltipLabel,
      content,
      menuItemStyleOverrides,
    }: Props,
    ref
  ) => {
    const [textInput, setTextInput] = useState(defaultInput ?? "");
    // Reset text input to default if it changes (e.g. updating report w new sort query)
    useEffect(() => {
      if (defaultInput && defaultInput !== textInput)
        setTextInput(defaultInput);
    }, [defaultInput]);

    return (
      <Tooltip title={tooltipLabel} placement="bottom-start">
        <div>
          <MuiMenuItem
            disableRipple
            className="flex flex-row justify-between"
            sx={{
              paddingTop: "8px",
              paddingBottom: "8px",
              background: styleProps?.highlightClassName,
              "&:hover": { backgroundColor: input ? "transparent" : "" },
              ...menuItemStyleOverrides,
            }}
            onClick={
              onClick
                ? (e) => {
                    if (!input) onClick(e);
                  }
                : undefined
            }
            ref={ref}
            disabled={disabled}
            onKeyDown={(e) => e.stopPropagation()} // stop keyboard shortcuts to fix text input
          >
            <div
              className={`flex w-full flex-row ${
                input ? "border px-2 py-2" : ""
              }`}
            >
              <div className="flex w-full flex-row items-center gap-2">
                {content}
                {Icon && (
                  <>
                    {typeof Icon === "string" ? (
                      <span
                        className={classNames(
                          "material-symbols-sharp h-5 w-5 text-[20px]",
                          styleProps && styleProps?.textClassName
                            ? styleProps?.textClassName
                            : "text-darkGray"
                        )}
                      >
                        {Icon}
                      </span>
                    ) : (
                      <Icon
                        className={
                          styleProps && styleProps?.textClassName
                            ? styleProps?.textClassName
                            : "text-darkGray"
                        }
                        sx={{ width: "20px", height: "20px" }}
                      />
                    )}
                  </>
                )}
                {IconElement}
                {TypeIndicatorIcon && (
                  <TypeIndicatorIcon
                    className={
                      styleProps && styleProps?.textClassName
                        ? styleProps?.textClassName
                        : "text-darkGray"
                    }
                    sx={{ width: "20px" }}
                  />
                )}
                {input ? (
                  <input
                    className="w-52 bg-transparent text-xs text-lightBlack outline-none"
                    placeholder={label}
                    value={textInput}
                    onChange={(e) => setTextInput(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key == "Enter" && onClick) {
                        onClick(e, textInput);
                      }
                    }}
                  />
                ) : (
                  <div
                    className={`text-sm font-medium ${
                      styleProps?.textClassName ?? ""
                    }`}
                  >
                    {label}
                  </div>
                )}
              </div>
              <div className="flex items-center justify-end">
                {rightContent}
              </div>
              {RightIcon && (
                <div
                  className="flex w-full flex-row justify-end gap-2"
                  onClick={
                    input && onClick ? (e) => onClick(e, textInput) : undefined
                  }
                >
                  {
                    <RightIcon
                      className={
                        input && !!textInput.length
                          ? "text-hebbiaBlue"
                          : "text-lightBlack"
                      }
                      sx={{ width: "20px" }}
                    />
                  }
                </div>
              )}
            </div>
          </MuiMenuItem>
        </div>
      </Tooltip>
    );
  }
);
