import {
  MatrixTemplateDisplay,
  ReportInfo,
} from "source/components/matrix/types/reports.types";
import moment from "moment";
import { MatrixGroup } from "./types";
import { groupBy } from "lodash";

export const substringMatchMatrices = (
  query: string,
  reports: ReportInfo[]
): ReportInfo[] => {
  // todo shared regex top be compiled?
  const regex = new RegExp(query.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"), "i");
  return reports.filter((report) => regex.test(report.name));
};

export const getMatrixGroup = (
  report: ReportInfo,
  showPinnedGrouping: boolean
) => {
  if (showPinnedGrouping && report.bookmarked) return MatrixGroup.PINNED;

  const today = moment().startOf("day");
  const yesterday = moment().subtract(1, "day");
  const createdAt = moment(report.created_at);

  const isToday = createdAt.isSameOrAfter(today, "day");
  const isYesterday = createdAt.isSameOrAfter(yesterday, "day");
  const dayDifference = today.diff(createdAt, "days");

  const currentYear = today.year();
  const createdAtYear = createdAt.year();

  if (isToday) {
    return MatrixGroup.TODAY;
  } else if (isYesterday) {
    return MatrixGroup.YESTERDAY;
  } else if (dayDifference < 8) {
    return MatrixGroup.PAST_7_DAYS;
  } else if (dayDifference < 31) {
    return MatrixGroup.PAST_30_DAYS;
  } else if (currentYear === createdAtYear) {
    return createdAt.format("MMMM");
  }
  return createdAt.format("YYYY");
};

export const getMatrixGroupLabel = (group: MatrixGroup) => {
  switch (group) {
    case MatrixGroup.PINNED:
      return "Pinned";
    case MatrixGroup.TODAY:
      return "Today";
    case MatrixGroup.YESTERDAY:
      return "Yesterday";
    case MatrixGroup.PAST_7_DAYS:
      return "Previous 7 days";
    case MatrixGroup.PAST_30_DAYS:
      return "Previous 30 days";
    default:
      return group;
  }
};

export const getSortOrderForMatrixGroup = (group: MatrixGroup) => {
  switch (group) {
    case MatrixGroup.PINNED: {
      return -5;
    }
    case MatrixGroup.TODAY: {
      return -4;
    }
    case MatrixGroup.YESTERDAY:
      return -3;
    case MatrixGroup.PAST_7_DAYS:
      return -2;
    case MatrixGroup.PAST_30_DAYS:
      return -1;
    default: {
      const isYear = detectIsYear(group);
      const monthToNumber = moment.utc().month(group).format("M");
      if (isYear) {
        // Invert year order so most recent shows first, but make sure the number is larger than months
        return Number.MAX_SAFE_INTEGER - parseInt(group);
      }

      // Invert month order so most recent shows first
      return 12 - parseInt(monthToNumber);
    }
  }
};

export const detectIsYear = (input: string) => {
  const year = moment(input, "YYYY", true); // Attempt to parse input as a year
  return year.isValid();
};

export const getTimeOfDay = () => {
  const currentHour = moment().hour();

  if (currentHour >= 5 && currentHour < 12) {
    return "morning";
  } else if (currentHour >= 12 && currentHour < 18) {
    return "afternoon";
  } else {
    return "evening";
  }
};

export const substringMatchTemplates = (
  query: string,
  templates: MatrixTemplateDisplay[]
): MatrixTemplateDisplay[] => {
  // todo shared regex top be compiled?
  const regex = new RegExp(query.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"), "i");
  return templates.filter(({ template }) => regex.test(template.name));
};

export const highlightSubstringMatch = (text: string, query: string) => {
  if (!query) return text; // If query is empty, return the original text

  const regex = new RegExp(`(${query})`, "gi"); // Create a regular expression with global and case-insensitive flags
  return text.replace(
    regex,
    '<b className="font-semibold text-primary">$1</b>'
  ); // Replace matched words with highlighted HTML
};

export const filterAndGroupMatrixList = (
  matrices: ReportInfo[],
  query: string,
  showPinnedGrouping: boolean
) => {
  const filteredMatrices = substringMatchMatrices(query ?? "", matrices);
  const groupedReports = groupBy(filteredMatrices, (report: ReportInfo) =>
    getMatrixGroup(report, showPinnedGrouping)
  );
  const sortedGroups = Object.entries(groupedReports)
    .map(([group, values]) => ({
      group,
      name: getMatrixGroupLabel(group as MatrixGroup),
      values,
    }))
    .sort(
      (a, b) =>
        getSortOrderForMatrixGroup(a.group as MatrixGroup) -
        getSortOrderForMatrixGroup(b.group as MatrixGroup)
    );

  return {
    filteredMatrices: sortedGroups.flatMap((group) => group.values),
    groupedMatrices: sortedGroups,
  };
};

export const isLessThanSevenDaysAway = (dateString: string): boolean => {
  const inputDate = new Date(dateString);
  const currentDate = new Date();
  const sevenDaysInMilliseconds = 7 * 24 * 60 * 60 * 1000;

  const timeDifference = currentDate.getTime() - inputDate.getTime();

  return timeDifference < sevenDaysInMilliseconds;
};
