import React, { useEffect, useState } from "react";
import { DraggableSidebar } from "source/components/gigabar/DraggableSidebar";
import { MIN_SIDEBAR_WIDTH } from "source/constants";
import { useWindowWidth } from "source/hooks/responsive/useWindowWidth";
import { MatrixSidebarContent } from "./MatrixSidebarContent";
import { MatrixSidebarVariant } from "../types";
import { useSelector } from "react-redux";
import { getSidebarVisible } from "source/redux/sidebar";

type Props = {
  variant: MatrixSidebarVariant;
};

export const Sidebar = ({ variant }: Props) => {
  const windowWidth = useWindowWidth();

  const isSidebarVisible = useSelector(getSidebarVisible);

  const [width, setWidth] = useState(MIN_SIDEBAR_WIDTH);

  /**
   * This is in place to automatically resize the width of the sidebar
   * when the user is making their window browser smaller
   */
  useEffect(() => {
    setWidth((prev) => Math.min(prev, windowWidth * 0.6));
  }, [windowWidth]);

  return (
    <DraggableSidebar
      collapsible
      open={isSidebarVisible}
      width={width}
      windowWidth={windowWidth}
      styleOverrides={{
        root: "shadow-none overflow-x-visible",
        draggableBorder: variant === "home" ? "!bg-transparent" : undefined,
      }}
      setWidth={setWidth}
    >
      <MatrixSidebarContent open={isSidebarVisible} variant={variant} />
    </DraggableSidebar>
  );
};
