import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import {
  useQueryReports,
  useQueryReportsMetadata,
} from "source/api/matrix/useQueryReports";
import { getCurrentOrg } from "source/redux/organization";
import { MatrixSidebarRow } from "./MatrixSidebarRow";
import { filterAndGroupMatrixList } from "../helpers";
import { Spinner } from "source/components/library/Spinner";
import { GroupedVirtuoso } from "react-virtuoso";
import { useGetRouter } from "source/hooks/useGetRouter";

type Props = {
  query: string;
};

export const MatrixList = ({ query }: Props) => {
  const { router } = useGetRouter();
  const matrixId = router.query.matrix_id;

  const matrixQuery = useQueryReports();
  const matrixMetadataQuery = useQueryReportsMetadata({
    // Limit retries to once to minimize possible blocking time
    retry: 1,
  });

  const currentOrg = useSelector(getCurrentOrg);

  const allMatrices = matrixQuery.data ?? [];
  const matrices = allMatrices.filter((report) =>
    currentOrg ? report.sheet_org_id === currentOrg.id : true
  );
  const matrixMetadataMap = matrixMetadataQuery.data?.result ?? {};
  const [activeMatrixSidebarRowId, setActiveMatrixSidebarRowId] = useState<
    string | null
  >(null);

  const { filteredMatrices, groupedMatrices } = useMemo(() => {
    return filterAndGroupMatrixList(matrices, query, true);
  }, [query, matrices]);

  if (matrixQuery.isLoading || matrixMetadataQuery.isLoading) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <Spinner styles="h-[24px] w-[24px] text-gray-100 dark:text-gray-400 fill-gray-300" />
      </div>
    );
  }

  return (
    <GroupedVirtuoso
      className="mt-0.5 h-full flex-1"
      defaultItemHeight={28}
      overscan={{ main: 500, reverse: 250 }}
      groupCounts={groupedMatrices.map(({ values }) => values.length)}
      groupContent={(index) => {
        return (
          <span className="block w-full bg-backgroundLight px-2 pt-2 text-xs leading-7 text-gray-500">
            {groupedMatrices[index]?.name}
          </span>
        );
      }}
      itemContent={(index) => {
        const value = filteredMatrices[index];

        if (!value) return null;

        const { id } = value;

        const active = id === activeMatrixSidebarRowId;
        const isUnread = matrixMetadataMap?.[id]?.unread ?? false;

        return (
          <MatrixSidebarRow
            key={id}
            active={active}
            isOpen={matrixId === id}
            isUnread={isUnread}
            reportInfo={value}
            styleOverrides="p-0"
            setActiveMatrixSidebarRowId={setActiveMatrixSidebarRowId}
          />
        );
      }}
    />
  );
};
