import React, { memo, useState } from "react";
import { MatrixHomeActions } from "./MatrixHomeActions";
import { MatrixList } from "./MatrixList";
import { NewMatrixButton } from "./NewMatrixButton";
import { classNames } from "core";
import { MatrixSidebarVariant } from "../types";

type Props = {
  open: boolean;
  variant: MatrixSidebarVariant;
};

export const MatrixSidebarContent = memo(({ open, variant }: Props) => {
  const [search, setSearch] = useState("");

  return (
    <div className="flex w-full">
      {!!open && (
        <div
          className={classNames(
            "top-0 z-50 flex h-screen w-full flex-col overflow-x-visible overflow-y-scroll overscroll-none px-3 pb-3 transition"
          )}
        >
          <div className="sticky top-0 justify-self-start bg-backgroundLight pt-[9px]">
            <NewMatrixButton />
          </div>
          <MatrixHomeActions
            search={search}
            variant={variant}
            setSearch={setSearch}
          />
          <MatrixList query={search} />
        </div>
      )}
    </div>
  );
});
