import React from "react";
import { useScreenIsMobileOrTablet } from "source/hooks/responsive/useScreenIsMobile";
import { ErrorBoundary } from "react-error-boundary";
import { TabBar } from "source/components/tab-bar/TabBar";
import dynamic from "next/dynamic";
import { ModalRenderer } from "source/components/modals/modalRenderer";
import { createPortal } from "react-dom";
import { MobileAppLayout } from "./MobileAppLayout";
import { ErrorPage } from "source/components/shared/ErrorPages/ErrorPage";
import { FlaggedBanner } from "source/components/shared/Banners/FlaggedBanner";
import { FooterBanner } from "source/components/shared/Banners/FooterBanner";
import { classNames } from "core";
import { NotificationRegion } from "source/components/alerts/NotificationRegion";

const DynamicDocViewerModal = dynamic(() =>
  import(
    "source/components/deprecated-doc-modal/DeprecatedDocViewerModal"
  ).then((mod) => mod.DeprecatedDocViewerModal)
);
const DynamicActiveBuildsModal = dynamic(() =>
  import("source/components/shared/Modals/ActiveBuildsModal").then(
    (mod) => mod.ActiveBuildsModal
  )
);
const DynamicDocStatusModal = dynamic(() =>
  import("source/components/shared/Modals/DocStatusModal").then(
    (mod) => mod.DocStatusModal
  )
);

type Props = {
  children: React.ReactNode;
  styleOverrides?: {
    main?: string;
  };
  SidebarComponent?: React.ReactNode;
  showTabBar?: boolean;
};

export const AppLayout = ({
  children,
  styleOverrides,
  SidebarComponent,
  showTabBar = true,
}: Props) => {
  const isMobile = useScreenIsMobileOrTablet();

  if (isMobile) {
    return <MobileAppLayout />;
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorPage}>
      <div className="flex h-screen w-full">
        {showTabBar && <TabBar />}
        {SidebarComponent}
        <main
          className={classNames(
            "relative flex max-h-full flex-grow flex-col bg-backgroundLight",
            styleOverrides?.main
          )}
        >
          {children}
        </main>
        <DynamicDocViewerModal />
        <DynamicActiveBuildsModal />
        <DynamicDocStatusModal />
        {createPortal(<NotificationRegion />, document.body)}
        <ModalRenderer />
        <FlaggedBanner />
        <FooterBanner />
      </div>
    </ErrorBoundary>
  );
};
