import { classNames } from "core";
import React from "react";

type DividerProps = {
  parentStyleOverride?: string;
  styleOverride?: string;
};

export const Divider = ({
  parentStyleOverride,
  styleOverride,
}: DividerProps) => (
  <div className="relative">
    <div
      className={classNames(
        "inset-0 flex items-center py-2",
        parentStyleOverride
      )}
      aria-hidden="true"
    >
      <div
        className={classNames("w-full border-t border-gray-300", styleOverride)}
      />
    </div>
  </div>
);
