import React from "react";
import Head from "next/head";
import { useSelector } from "react-redux";
import { getDisplayReportName } from "source/redux/matrix";

export const MatrixPageTitle = () => {
  const reportName = useSelector(getDisplayReportName);

  return (
    <Head>
      <title>{`${reportName ? reportName + " | " : ""}Hebbia Matrix`}</title>
    </Head>
  );
};
