import React from "react";
import { MenuItemData } from "source/components/shared/Menu/types";
import { DropdownMenu } from "source/components/shared/Menu/DropdownMenu";

type Props = {
  isOwner: boolean;
  bookmarked: boolean;
  handleRenameMatrix: () => void;
  handleBookmarkReport: () => void;
  handleMatrixDelete: () => void;
  handleContextMenuOpen: () => void;
  handleContextMenuClose: () => void;
};

export const MatrixSidebarContextMenu = ({
  isOwner,
  bookmarked,
  handleRenameMatrix,
  handleBookmarkReport,
  handleMatrixDelete,
  handleContextMenuOpen,
  handleContextMenuClose,
}: Props) => {
  const menuItemsData: MenuItemData[] = [
    {
      label: `${bookmarked ? "Remove pin" : "Pin"}`,
      IconElement: (
        <span className="material-symbols-sharp text-[16px] text-gray-500">
          {bookmarked ? "keep_off" : "keep"}
        </span>
      ),
      styleProps: {
        textClassName: "!text-sm !leading-5 !font-normal text-neutral-800",
      },
      callback: () => {
        handleBookmarkReport();
      },
    },

    ...(isOwner
      ? [
          {
            label: "Rename",
            IconElement: (
              <span className="material-symbols-outlined text-[16px] text-gray-500">
                border_color
              </span>
            ),
            styleProps: {
              textClassName:
                "!text-sm !leading-5 !font-normal text-neutral-800",
            },
            callback: () => {
              handleRenameMatrix();
            },
          },
          {
            type: "divider",
          } as MenuItemData,
          {
            label: "Delete",
            IconElement: (
              <span className="material-symbols-sharp text-[16px] text-gray-500">
                delete
              </span>
            ),
            styleProps: {
              textClassName:
                "!text-sm !leading-5 !font-normal text-neutral-800",
            },
            callback: () => {
              handleMatrixDelete();
            },
          },
        ]
      : []),
  ];

  return (
    <DropdownMenu
      menuItemsData={menuItemsData}
      label={
        <span className="material-symbols-rounded text-base leading-none text-gray-500">
          more_horiz
        </span>
      }
      styleOverrides="h-5 w-5 px-0 py-px"
      menuStyleOverrides={{
        borderRadius: "6px",
        paddingX: "4px",
      }}
      menuItemStyleOverrides={{
        paddingX: "8px",
        paddingY: "5px",
        borderRadius: "4px",
        fontFamily: "Inter",
        color: "var(--h-text-black, #27272B)",
      }}
      menuListClassOverrides="!py-[6px]"
      menuWidth="180px"
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      onClick={handleContextMenuOpen}
      onClose={handleContextMenuClose}
    />
  );
};
