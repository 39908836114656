import { useEffect } from "react";

/**
 * https://stackoverflow.com/questions/32553158/detect-click-outside-react-component
 */
export const useOutsideClick = (
  ref: any,
  onClickOut: (e: any) => void,
  deps: unknown[] = []
) => {
  useEffect(() => {
    const onClick = (e: any) => !ref?.contains(e.target) && onClickOut?.(e);
    document.addEventListener("mousedown", onClick, true);
    return () => document.removeEventListener("mousedown", onClick, true);
  }, deps);
};
