import React, { useState } from "react";
import { Spinner } from "source/components/library/Spinner";
import { useSelector } from "react-redux";
import { useCreateReport } from "source/hooks/matrix/useCreateReport";
import { useGlobalNavigator } from "source/hooks/useSetRouter";
import { getCurrentOrg } from "source/redux/organization";
import logger from "source/utils/logger";
import { Tooltip } from "source/components/shared/Tooltip";
import { Button, MaterialSymbolsIcon } from "core";

export const NewMatrixButton = () => {
  const orgId = useSelector(getCurrentOrg)?.id;
  const { goToMatrix } = useGlobalNavigator();
  const { createReport } = useCreateReport();
  const [isCreating, setIsCreating] = useState(false);

  const onCreateReport = async () => {
    if (isCreating) return;
    if (!orgId) {
      logger.error("Trying to create matrix with no orgId");
      return;
    }
    setIsCreating(true);
    const report = await createReport({
      orgId,
      replaceReport: true,
    });
    goToMatrix(report.id, report.tabs[0]?.tab_id);
    setIsCreating(false);
  };

  return (
    <div className="group h-[44px]">
      <Tooltip title="Create a new matrix">
        <Button
          variant="text"
          disabled={isCreating}
          className="w-full justify-between rounded-[4px] border-0 p-2 text-lg text-gray-800 shadow-none hover:bg-gray-200 focus-visible:bg-gray-200"
          onClick={onCreateReport}
        >
          <span>Matrix</span>
          <div className="flex items-center text-sm">
            {isCreating ? (
              <Spinner styles="h-3 w-3 text-gray-500" />
            ) : (
              <MaterialSymbolsIcon
                icon="edit_square"
                size="lg"
                variant="outlined"
              />
            )}
          </div>
        </Button>
      </Tooltip>
    </div>
  );
};
