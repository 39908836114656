import React, { useEffect, useRef, useState } from "react";
import { MatrixSidebarRowWrapper } from "./MatrixSidebarRow";
import { useOutsideClick } from "source/hooks/useOutsideClick";
import { useDispatch } from "react-redux";
import { setMatrixHomePanel } from "source/redux/matrix";
import { MatrixHomePanelType, MatrixSidebarVariant } from "../types";
import { MaterialSymbolsIcon } from "core";
import { useGlobalNavigator } from "source/hooks/useSetRouter";

type Props = {
  search: string;
  variant: MatrixSidebarVariant;
  setSearch: (v: string) => void;
};

export const MatrixHomeActions = ({ search, variant, setSearch }: Props) => {
  const { goToMatrixHome } = useGlobalNavigator();

  const searchRef = useRef<HTMLInputElement>(null);

  const [isSearching, setIsSearching] = useState(false);

  const dispatch = useDispatch();

  useOutsideClick(
    searchRef.current,
    (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setIsSearching(false);
      }
    },
    []
  );

  useEffect(() => {
    searchRef.current?.focus();
  }, [isSearching]);

  return (
    <div
      className="mt-2.5 flex flex-col"
      style={{
        fontVariationSettings: `'FILL' 1, 'wght' 400, 'GRAD' 0, 'opsz' 24`,
      }}
    >
      <MatrixSidebarRowWrapper
        onClick={() => {
          dispatch(setMatrixHomePanel(MatrixHomePanelType.TEMPLATE_LIBRARY));

          if (variant === "matrix") {
            goToMatrixHome(true);
            return;
          }
        }}
      >
        <div className="flex flex-1 items-center gap-2">
          <MaterialSymbolsIcon
            icon="grid_view"
            variant="outlined"
            size="base"
            className="leading-6 text-gray-500"
          />
          <span className="text-xs font-medium leading-4 text-gray-800">
            Explore Templates
          </span>
        </div>
      </MatrixSidebarRowWrapper>
      <MatrixSidebarRowWrapper onClick={() => setIsSearching(true)}>
        <MaterialSymbolsIcon
          icon="search"
          variant="outlined"
          size="base"
          className="leading-6 text-gray-500"
        />
        {isSearching ? (
          <>
            <input
              type="text"
              value={search}
              placeholder="Search matrices"
              onChange={(e) => setSearch(e.target.value as string)}
              className="flex-1 border-none bg-transparent text-xs font-medium leading-4 text-gray-800 placeholder-gray-400 outline-none transition-all"
              ref={searchRef}
            />
            {search !== "" && (
              <MaterialSymbolsIcon
                icon="close"
                size="base"
                variant="outlined"
                className="cursor-pointer self-end leading-6 text-gray-500"
                onClick={(e) => {
                  e.preventDefault();
                  setSearch("");
                  setIsSearching(false);
                }}
              />
            )}
          </>
        ) : (
          <>
            <span className="flex-1 text-xs font-medium leading-4 text-gray-800">
              {search.length ? search : "Search matrices"}
            </span>
            {search !== "" && (
              <MaterialSymbolsIcon
                icon="close"
                size="base"
                variant="outlined"
                className="cursor-pointer self-end leading-6 text-gray-500"
                onClick={(e) => {
                  e.preventDefault();
                  setSearch("");
                  setIsSearching(false);
                  searchRef.current?.blur();
                }}
              />
            )}
          </>
        )}
      </MatrixSidebarRowWrapper>
    </div>
  );
};
