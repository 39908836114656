import React from "react";
import { MenuItem } from "./MenuItem";
import { NestedMenuItem } from "./NestedMenuItem";
import { MenuItemData } from "./types";
import { Divider } from "source/components/library/Divider";
import { LoadingRow } from "source/components/sidebar/components/LoadingRow";
import { SxProps, Theme } from "@mui/material";

export const generateNestedMenuItems = (
  menuItemsData: MenuItemData[],
  isOpen: boolean,
  handleClose: () => void,
  isLoading?: boolean,
  menuStyleOverrides?: SxProps<Theme>,
  menuItemStyleOverrides?: SxProps<Theme>,
  menuListClassOverrides?: string,
  dividerParentStyleOverrides?: string
) => {
  if (isLoading) return <LoadingRow depth={1} />;
  return menuItemsData.map((item, idx) => {
    const {
      type,
      items,
      label,
      Icon,
      IconElement,
      TypeIndicatorIcon,
      callback,
      disabled,
      input,
      defaultInput,
      rightIcon,
      styleProps,
      tooltipLabel,
      content,
    } = item;
    if (type === "divider")
      return (
        <div key={`divider-${idx}`}>
          <Divider
            styleOverride="border-gray-100"
            parentStyleOverride={dividerParentStyleOverrides}
          />
        </div>
      );
    else if (!label && !content) return null;
    else if (type === "header")
      return (
        <div
          className="px-3 py-2 text-xs font-medium text-darkGray"
          key={`label-${idx}`}
        >
          {label}
        </div>
      );

    if (items && items.length) {
      // Item contains nested items. Recursively call generateNestedMenuItems to
      // generate the child menu
      return (
        <NestedMenuItem
          Icon={Icon}
          TypeIndicatorIcon={TypeIndicatorIcon}
          label={label ?? ""}
          key={idx}
          parentMenuOpen={isOpen}
          disabled={disabled}
          RightIconOverride={rightIcon}
          styleProps={styleProps}
          menuStyleOverrides={menuStyleOverrides}
          menuItemStyleOverrides={menuItemStyleOverrides}
          menuListClassOverrides={menuListClassOverrides}
        >
          {generateNestedMenuItems(
            items,
            isOpen,
            handleClose,
            isLoading,
            menuStyleOverrides,
            menuItemStyleOverrides,
            menuListClassOverrides
          )}
        </NestedMenuItem>
      );
    }

    // No nested children, this is a menu item
    return (
      <MenuItem
        Icon={Icon}
        IconElement={IconElement}
        TypeIndicatorIcon={TypeIndicatorIcon}
        label={label ?? ""}
        key={idx}
        onClick={(
          event:
            | React.MouseEvent<HTMLElement>
            | React.KeyboardEvent<HTMLElement>,
          textOverride?: string
        ) => {
          event.stopPropagation();
          handleClose();
          callback && callback(event, item, textOverride);
        }}
        disabled={disabled}
        RightIcon={rightIcon}
        input={input}
        defaultInput={defaultInput}
        styleProps={styleProps}
        tooltipLabel={tooltipLabel}
        content={content}
        menuItemStyleOverrides={menuItemStyleOverrides}
      />
    );
  });
};
