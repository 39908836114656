import React, { useState, MouseEvent } from "react";
import { Menu as MuiMenu, PopoverOrigin, SxProps, Theme } from "@mui/material";
import { MenuItemData } from "./types";
import { generateNestedMenuItems } from "./generators";
import { classNames } from "core";

export type DropdownMenuProps = {
  label: JSX.Element | string;
  disabled?: boolean;
  isLoading?: boolean;
  anchorOrigin?: PopoverOrigin | undefined;
  transformOrigin?: PopoverOrigin | undefined;
  menuItemsData: MenuItemData[];
  anchorRef?: React.RefObject<HTMLDivElement>;
  menuWidth?: string;
  styleOverrides?: string;
  menuStyleOverrides?: SxProps<Theme> | undefined;
  menuItemStyleOverrides?: SxProps<Theme> | undefined;
  menuListClassOverrides?: string;
  dividerParentStyleOverrides?: string;
  activeLabelStyle?: string;
  onClick?: (e: any) => void;
  onClose?: () => void;
};

export const DropdownMenu = ({
  label,
  disabled,
  menuWidth,
  isLoading,
  anchorOrigin,
  transformOrigin,
  menuItemsData,
  styleOverrides,
  menuStyleOverrides,
  menuItemStyleOverrides,
  menuListClassOverrides,
  dividerParentStyleOverrides,
  activeLabelStyle,
  onClick,
  onClose,
}: DropdownMenuProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const open = !!anchorEl;
  // If ref is passed in, use that as anchor element, otherwise use current click target
  const handleClick = (e: MouseEvent<HTMLDivElement>) => {
    onClick && onClick(e);
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    onClose && onClose();
    setAnchorEl(null);
  };

  const menuContent = generateNestedMenuItems(
    menuItemsData,
    open,
    handleClose,
    isLoading,
    menuStyleOverrides,
    menuItemStyleOverrides,
    menuListClassOverrides,
    dividerParentStyleOverrides
  );

  return (
    <>
      <div
        onClick={handleClick}
        className={classNames(
          "select-none px-2 hover:cursor-pointer",
          disabled && "pointer-events-none",
          styleOverrides,
          open && activeLabelStyle
        )}
      >
        {label}
      </div>
      <MuiMenu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        slotProps={{
          paper: {
            square: true,
            sx: {
              width: "fit-content",
              minWidth: menuWidth ? menuWidth : "14rem",
              boxShadow:
                "0 10px 15px -3px rgb(0, 0, 0, 0.1), 0 4px 6px -4px rgb(0, 0, 0, 0.1)",
              border: "1px solid #E5E7EB",
              ...menuStyleOverrides,
            },
          },
        }}
        classes={{ list: menuListClassOverrides }}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
      >
        {menuContent}
      </MuiMenu>
    </>
  );
};
